import modalProps from '../../../modalProps'
import { createCustomerContact, updateCustomerContact } from '../../../../../Api/customerContacts'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async () => {

  try {

    const { id, customerId } = modalProps()
    
    const data = {
      ...values,
      customer_id: customerId,
      telephone: values.telephone || null,
      email: values.email || null,
      role: values.role || null,
      hubspot_id: values.hubspot_id || null,
    }

    let response

    if (id) {

      response = await updateCustomerContact({ id, data })

    } else {

      response = await createCustomerContact({ data })

    }

    modalProps().onClose(response.data.data)

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
