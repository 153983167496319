import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field } from 'react-final-form'
import { Form, Input, Select, Popover, Spin } from 'antd'
import pluralize from 'pluralize'
import { FormInterceptor } from '../../../../../packages/sota'
import { ModelSelect } from '../../../../Modules'
import modalProps from '../../modalProps'
import formService from './formService'
import styles from './Component.css'

export default class FormFields extends React.PureComponent {

  static propTypes = {
    users: PropTypes.array.isRequired,
    customerPaymentTerms: PropTypes.array.isRequired,
    initialValues: PropTypes.object,
    matches: PropTypes.array.isRequired,
    onChangeFormState: PropTypes.func.isRequired,
    onChangeName: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
  }

  render () {

    const {
      users,
      customerPaymentTerms,
      initialValues,
      matches,
      onChangeFormState,
      onChangeName,
      onSubmit,
      validate,
    } = this.props

    if (!initialValues) return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
        <Spin />
      </div>
    )

    const isNew = !modalProps().id

    return (

      <FormProvider
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, submitting }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            autoComplete="off"
          >

            <FormInterceptor
              ref={formService}
              subscription={{ submitting: true }}
              onChange={onChangeFormState}
            />

            <Field name="name">
              {({ input, meta }) => (
                <Form.Item
                  label="Name"
                  required
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  help={!!matches.length && (
                    <Popover
                      title='Matches'
                      content={(
                        <div className={styles.matches}>
                          {matches.map(supplier => <div key={supplier.id}>{`• ${supplier.name}`}</div>)}
                        </div>
                      )}
                    >
                      <a>{`${matches.length} potential ${pluralize('duplicate', matches.length)} found`}</a>
                    </Popover>
                  )}
                >
                  <Input
                    {...input}
                    onChange={e => {
                      input.onChange(e)
                      if (isNew) onChangeName(e.target.value)
                    }}
                    disabled={submitting}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name="manager_id">
              {({ input, meta }) => (
                <Form.Item
                  label="Manager"
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  className={styles.formItem}
                >
                  <Select
                    {...input}
                    value={input.value || undefined}
                    disabled={submitting}
                    placeholder='Select User'
                    allowClear
                  >
                    <Select.OptGroup label='Active'>
                      {users.filter(user => !user.deactivated_at).map(({ id, name }) => (
                        <Select.Option key={id} value={id}>{name}</Select.Option>
                      ))}
                    </Select.OptGroup>
                    <Select.OptGroup label='Deactivated'>
                      {users.filter(user => !!user.deactivated_at).map(({ id, name }) => (
                        <Select.Option key={id} value={id} style={{ color: '#9e9e9e' }}>{name}</Select.Option>
                      ))}
                    </Select.OptGroup>
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name="telephone">
              {({ input }) => (
                <Form.Item
                  label="Telephone"
                >
                  <Input
                    {...input}
                    disabled={submitting}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name="email">
              {({ input }) => (
                <Form.Item
                  label="Email"
                >
                  <Input
                    {...input}
                    disabled={submitting}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name="currency">
              {({ input, meta }) => (
                <Form.Item
                  label="Currency"
                  required
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <ModelSelect.Currency
                    {...input}
                    disabled={submitting}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name="pref_service">
              {({ input }) => (
                <Form.Item
                  label="Pref. Service"
                >
                  <ModelSelect.Service
                    {...input}
                    disabled={submitting}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name="payment_terms">
              {({ input, meta }) => (
                <Form.Item
                  label="Terms"
                  required
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Select
                    {...input}
                  >
                    {customerPaymentTerms.map(customerPaymentTerm => (
                      <Select.Option key={customerPaymentTerm} value={customerPaymentTerm}>
                        {customerPaymentTerm}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name="sage_ref">
              {({ input }) => (
                <Form.Item
                  label="Sage Ref"
                >
                  <Input
                    {...input}
                    disabled={submitting}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name="hubspot_id">
              {({ input }) => (
                <Form.Item
                  label="HubSpot ID"
                >
                  <Input
                    {...input}
                    disabled={submitting}
                  />
                </Form.Item>
              )}
            </Field>

          </Form>

        )}

      </FormProvider>

    )

  }

}
