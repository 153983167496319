import { sendCustomerEnquiryToHubSpot } from '../../../Api/customerEnquiries'
import fetch from './fetch'
import { handleException } from '../../../../packages/sota'

export default (key) => async (dispatch, getState) => {

  const { customerEnquiry } = getState().screens.CustomerEnquiry

  try {

    if (key === 'enquiry') {
      await sendCustomerEnquiryToHubSpot({ id: customerEnquiry.id })
    }

    dispatch(fetch())

  } catch (error) {

    handleException(error)

  }
  
}
