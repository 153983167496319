import { Modal } from 'antd'
import { upperFirst } from 'lodash'
import sendToHubSpot from '../../../Services/sendToHubSpot'

export default (key) => async (dispatch) => {

  const resource = upperFirst(key)

  Modal.confirm({
    title: `Send ${resource} to HubSpot?`,
    content: `This will send the ${resource} to HubSpot. Are you sure you want to continue?`,
    okText: 'Yes',
    onOk: () => dispatch(sendToHubSpot(key)),
  })

}
