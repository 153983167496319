import { connect } from "react-redux"
import Header from "./Header"

import selectActiveTab from "../../Selectors/selectActiveTab"

import onBack from './Handlers/onBack'
import onChangeStatus from './Handlers/onChangeStatus'
import onQuote from './Handlers/onQuote'
import onSalesOrderExport from './Handlers/onSalesOrderExport'
import onPurchaseOrdersExport from './Handlers/onPurchaseOrdersExport'
import onSendToHubSpot from './Handlers/onSendToHubSpot'
import onEdit from './Handlers/onEdit'
import onDelete from './Handlers/onDelete'
import onChangeTab from './Handlers/onChangeTab'
import onClickMoreMenuItem from './Handlers/onClickMoreMenuItem'

const mapStateToProps = (state) => ({
  customerEnquiryStatuses: state.core.customerEnquiryStatuses,
  customerEnquiry: state.screens.CustomerEnquiry.customerEnquiry,
  activeTab: selectActiveTab(state),
  fileChanges: state.screens.CustomerEnquiry.fileChanges,
})

const actionCreators = {
  onBack,
  onChangeStatus,
  onQuote,
  onSalesOrderExport,
  onPurchaseOrdersExport,
  onSendToHubSpot,
  onEdit,
  onDelete,
  onChangeTab,
  onClickMoreMenuItem,
}

export default connect(mapStateToProps, actionCreators)(Header)
